$screen-md-max: 899px;
$md-only: '( max-width: #{ $screen-md-max } )';
$screen-md-min: 900px;
$md-bigger: '( min-width: #{ $screen-md-min } )';


$screen-xs-max: 639px;
$xs-only: '( max-width: #{ $screen-xs-max } )';
$screen-xs-min: 640px;
$xs-bigger: '( min-width: #{ $screen-xs-min } )';

@mixin respond-to($media) {
  @media #{$media} {
    @content; } }

.xs-only {
  @include respond-to($xs-bigger) {
    display: none !important; } }

.md-only {
  @include respond-to($md-bigger) {
    display: none !important; } }

.xs-bigger {
  @include respond-to($xs-only) {
    display: none !important; } }

.md-bigger {
  @include respond-to($md-only) {
    display: none !important; } }
