.loading-spinner {
  height: 30px;
  width: 30px;
  animation: spin .5s linear 0s infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
