.login-signup-form {
  max-width: 350px;
  text-align: center;
  margin: 0 auto;
  padding: 40px;

  @include respond-to($md-only) {
    padding: 20px; }

  &__input-group {
    display: block;
    margin: 0 auto 20px; }

  &__input {
    width: 100%;
    font-size: 18px;
    padding: 14px; }

  &__label {
    margin: 5px 0;
    display: block;
    text-align: left; }

  &__submit {
    margin-top: 20px; }

  &__link {
    transition: all $transition-time;
    color: $col-gray;
    display: block;
    &:hover {
      color: $col-gray-light; } } }
