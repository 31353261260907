.header {
  color: $col-black;
  padding: 40px 30px 0;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.94);
  border-bottom: 20px solid $col-green;
  overflow: hidden;
  @include respond-to($md-only) {
    padding: 15px 25px 0;
    border-bottom: 10px solid $col-green; }
  text-align: left;
  background-color: white;

  &__wrap {
    position: relative;
    margin: 0 auto;
    max-width: $max-width; }

  &__links {
    float: right; }

  &__link {
    border: 0;
    color: $col-black;
    background-color: transparent;
    transition: opacity .2s;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    line-height: 45px;
    margin-left: 40px;
    opacity: 0.6;
    font-weight: 700;
    &:first-child {
      margin-left: 0; }
    &:hover {
      opacity: 1; }
    @include respond-to($md-only) {
      line-height: 30px;
      margin-left: 15px;
      font-size: 15px; } }

  &__logo {
    display: inline-block;
    line-height: 1;

    &__img {
      height: 60px;
      cursor: pointer;
      margin-top: 10px;
      @include respond-to($md-only) {
        height: 25px;
        margin-top: 8px; } } }

  h1 {
    margin: 0;
    display: none; } }
