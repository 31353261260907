* {
  box-sizing: border-box; }

p {
  line-height: 1.5; }

body, button, input, textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', 'Arial', sans-serif; }

body {
  background: $col-bg;
  margin: 0;
  color: $col-black;
  min-height: 100vh;
  height: 100%; }

a, a:visited, a:active {
  text-decoration: none; }

button {
  outline: none;
  cursor: pointer; }

input, textarea {
  outline: 0;
  border-radius: 3px;
  padding: 8px;
  border: 1px solid #bbb; }

.inline-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.hide-mobile {
  @include respond-to($md-only) {
    display: none !important; } }

.table-wrap {
  width: 100%;
  display: table;
  table-layout: fixed;

  &__cell {
    display: table-cell; } }
