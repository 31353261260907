.path-navigation {
  box-shadow: 0 2px 3px -2px rgba(0,0,0,0.94);

  &__wrap {
    text-align: left;
    max-width: $max-width;
    margin: 0 auto;
    @include respond-to($xs-only) {
      padding: 20px 10px; } }

  &__logo {
    height: 40px; }

  &__last-node, &__node {
    display: inline-block;
    text-align: center;
    @include respond-to($xs-only) {
      display: block; }
    vertical-align: middle; }

  &__content {
    display: inline-block;
    vertical-align: middle; }

  &__arrow {
    display: inline-block;
    @include respond-to($xs-only) {
      display: block;
      margin: 0 auto; }
    vertical-align: middle; }

  &__arrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid $col-green;
    @include respond-to($xs-only) {
      border-bottom: none;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $col-green; } }


  &__content {
    padding: 20px 20px;
    margin: 20px 15px;
    @include respond-to($xs-only) {
      padding: 10px 20px;
      margin: 0px 15px; }

    &__headline {
      margin: 0; }
    &__description {
      margin: 0; } }

  &__last-node {
    &__content {
      border-radius: $border-radius;
      background-color: white;
      border: 1px solid $col-gray-light;
      max-width: 300px; } }

  &__node {
    &__content {
      transition: all .2s;
      border: 1px solid $col-gray-light;
      border-radius: $border-radius;
      cursor: pointer;
      &.over {
        border: 3px dotted $col-green; }
      &:hover {
        border: 1px solid $col-green;
        background-color: white; } } } }
