$col-bg: #efefef;
$col-black: #444;
$col-gray-light: #ccc;
$col-gray: #aaa;

$col-green: #166;
$col-green-dark: #035252;

$col-orange: #f81;
$col-orange-dark: #CE6600;

$col-red: #c33;
$col-red-light: #fdd;
