.btn-reset {
  border: none;
  background-color: transparent; }

.btn {
  transition: background-color $transition-time, color $transition-time;
  cursor: pointer;
  border-radius: 5px;
  border: none;

  &--large {
    font-size: 30px;
    padding: 15px 40px;
    font-weight: bold;
    @include respond-to($md-only) {
      font-size: 24px;
      padding: 12px 30px; } }

  &--small {
    font-size: 18px;
    padding: 10px 20px; }

  &--tiny {
    font-size: 14px;
    padding: 5px 15px; }

  &--green {
    background-color: $col-green;
    color: white;
    &:hover {
      background-color: rgba($col-green, 0.8); } }

  &--white {
    background-color: white;
    color: $col-black;
    &:hover {
      background-color: #efefef; } }

  &--transparent {
    border: 1px solid #ccc;
    background-color: transparent;
    color: $col-gray;
    &:hover {
      color: $col-black;
      background-color: white; } }

  &--square {
    height: 50px;
    min-width: 50px; } }
