.profile {
  margin: 0 auto;
  max-width: $max-width;
  padding: 50px 0;
  @include respond-to($md-only) {
    padding: 20px 10px; }
  text-align: center;

  &__button {
    display: inline-block;
    margin: 0 auto 30px; }

  &__stats {
    margin: 0 auto 30px;
    position: relative;
    @include respond-to($md-bigger) {
      display: table;
      table-layout: fixed; } } }
