.sunburst {
  display: table-cell;
  width: 50%;
  @include respond-to($md-only) {
    display: block;
    width: 100%; }
  vertical-align: middle;
  overflow: visible;

  &__tooltip {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: rgba(255,255,255,0.9);
    border-radius: $border-radius;
    text-align: left;
    pointer-events: none;
    padding: 0 20px;
    max-width: 300px;
    h3 {
      font-size: 16px; }
    p {
      font-size: 12px; } } }
