.reload-modal {
  padding: 40px;
  text-align: center;

  &__msg {
    color: $col-orange; }

  &__btn {
    &__icon {
      padding-right: 10px; } } }
