.modal-enter {
  opacity: 0.01; }

.modal-enter.modal-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out; }

.modal-leave {
  opacity: 1; }

.modal-leave.modal-leave-active {
  opacity: 0.01;
  transition: opacity 150ms ease-in; }

.fa-spinner {
  animation: spin .5s linear 0s infinite; }

@keyframes: spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
