.footer {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__item {
    transition: all .2s;
    color: $col-gray;
    opacity: 0.8;
    margin: 0 10px;
    line-height: 1;
    font-size: 16px;
    &:hover {
      color: $col-black; } } }
