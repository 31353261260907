.login-signup-toggle {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 20px;
  border: 1px solid $col-gray-light;
  border-radius: $border-radius;
  font-size: $font-lg;

  &__link {
    display: table-cell;
    padding: 20px 0;
    color: $col-gray;
    border-radius: $border-radius;

    &--active {
      background-color: white;
      color: $col-black; } } }
