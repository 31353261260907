.imprint {
  color: #666;
  max-width: $max-width;
  margin: 0 auto;
  @include respond-to($md-only) {
    padding: 0 20px; }

  p {
    lint-height: 1;
    margin: 0; } }
