// -----------------
// MODALS
// -----------------
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255, 0.7);
  overflow-y: scroll;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.modal {
  margin: 20px;
  border-radius: 5px;
  background-color: white;
  position: relative;
  border: 1px solid #bbb;
  max-width: 700px;
  @include respond-to($md-only) {
    width: 100%; }

  &--padded {
    padding: 40px;
    @include respond-to($md-only) {
      padding: 20px; } }

  &__icon-button {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.3;
    background-color: transparent;
    &:hover {
      opacity: 1; } }

  .key-command {
    display: inline-block;
    width: 300px; }
  .description {
    display: table;
    .description-item {
      display: table-cell;
      vertical-align: middle;
      padding: 10px;
      &:first-child {
        width: 120px;
        text-align: center;
        font-weight: 700; } } } }

.keyboard-icon {
  padding: 15px 20px;
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px; }
