.profile-statistics {
  display: block;
  width: 100%;
  font-size: 16px;
  vertical-align: middle;
  line-height: 1;
  text-align: left;
  @include respond-to($md-bigger) { // Mobile first
    font-size: 30px;
    display: table-cell;
    width: 50%;
    padding-left: 50px; }


  &__row {
    margin: 0; }

  &__border {
    height: 20px;
    border-radius: 2px;
    margin-bottom: 10px;

    &__total {
      border: 1px solid $col-gray-light;
      background-color: $col-gray-light; }

    &__completed {
      border: 1px solid $col-green;
      background-color: $col-green; }

    &__marked {
      border: 1px solid $col-orange;
      background-color: $col-orange; } } }
